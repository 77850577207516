export function slide() {
  new Splide("#splide1", {
    type: "loop", // スライドのエフェクト：スライド
    fixedWidth: '231px',
    gap: "20px",
    arrows: false,
    pagination: false,
    breakpoints: { // ブレイクポイントの設定
      768: {
        fixedWidth: '115px',
        gap: "10px",
      },
    },
    autoScroll: {
      speed: 0.5, // スクロール速度
      pauseOnHover: false, // カーソルが乗ってもスクロールを停止させない
    }
  }).mount(window.splide.Extensions);

  new Splide("#splide2", {
    type: "loop", // スライドのエフェクト：スライド
    fixedWidth: '350px',
    gap: "14px",
    arrows: false,
    pagination: false,
    breakpoints: { // ブレイクポイントの設定
      768: {
        fixedWidth: '175px',
        gap: "10px",
      },
    },
    autoScroll: {
      speed: 0.5, // スクロール速度
      pauseOnHover: false, // カーソルが乗ってもスクロールを停止させない
    }
  }).mount(window.splide.Extensions);
}
